// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  --ion-color-primary: #91cddb;
  --ion-color-primary-rgb: 145, 205, 219;
  --ion-color-primary-contrast: #000000;
  --ion-color-primary-contrast-rgb: 0, 0, 0;
  --ion-color-primary-shade: #80b4c1;
  --ion-color-primary-tint: #9cd2df;

  --ion-color-secondary: #32849a;
  --ion-color-secondary-rgb: 50, 132, 154;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #2c7488;
  --ion-color-secondary-tint: #4790a4;

  --ion-color-tertiary: #58585b;
  --ion-color-tertiary-rgb: 88, 88, 91;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4d4d50;
  --ion-color-tertiary-tint: #69696b;

  --ion-color-success: #009244;
  --ion-color-success-rgb: 0, 146, 68;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #00803c;
  --ion-color-success-tint: #1a9d57;

  --ion-color-warning: #f18f2a;
  --ion-color-warning-rgb: 241, 143, 42;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #d47e25;
  --ion-color-warning-tint: #f29a3f;

  --ion-color-danger: #ed1c24;
  --ion-color-danger-rgb: 237, 28, 36;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #d11920;
  --ion-color-danger-tint: #ef333a;

  --ion-color-dark: #1c232a;
  --ion-color-dark-rgb: 28, 35, 42;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #191f25;
  --ion-color-dark-tint: #33393f;

  --ion-color-medium: #989898;
  --ion-color-medium-rgb: 152, 152, 152;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #868686;
  --ion-color-medium-tint: #a2a2a2;

  --ion-color-light: #bccbd4;
  --ion-color-light-rgb: 188, 203, 212;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #a5b3bb;
  --ion-color-light-tint: #c3d0d8;

  --ion-color-step-350: #ffffff;

  /* Ropeway line properties */
  --sis-ropeway-line-stroke-width: 3;
  --sis-ropeway-line-border-stroke-width: 8;
  --sis-ropeway-line-highlight-stroke-width: 16;
  --sis-ropeway-line-invisible-stroke-width: 20;
  --sis-ropeway-line-stroke-linecap: round;
  --sis-ropeway-line-stroke-linejoin: round;

  /** Ropeway Offline **/
  --sis-color-ropewayoffline: var(--ion-color-medium, #8f8f8f);
  --sis-color-ropewayoffline-rgb: var(--ion-color-medium-rgb, [143, 143, 143]);
  --sis-color-ropewayoffline-contrast: var(--ion-color-medium-contrast, #000000);
  --sis-color-ropewayoffline-contrast-rgb: var(--ion-color-medium-contrast-rgb, [0, 0, 0]);
  --sis-color-ropewayoffline-shade: var(--ion-color-medium-shade, #7e7e7e);
  --sis-color-ropewayoffline-tint: var(--ion-color-medium-tint, #9a9a9a);

  /** Ropeway Running **/
  --sis-color-ropewayrunning: var(--ion-color-primary, #0a741a);
  --sis-color-ropewayrunning-rgb: var(--ion-color-primary-rgb, [10, 116, 26]);
  --sis-color-ropewayrunning-contrast: var(--ion-color-primary-contrast, #ffffff);
  --sis-color-ropewayrunning-contrast-rgb: var(--ion-color-primary-contrast-rgb, [255, 255, 255]);
  --sis-color-ropewayrunning-shade: var(--ion-color-primary-shade, #096617);
  --sis-color-ropewayrunning-tint: var(--ion-color-primary-tint, #238231);

  /** Ropeway Drive Ready **/
  --sis-color-ropewaydriveready: var(--ion-color-dark, #000000);
  --sis-color-ropewaydriveready-rgb: var(--ion-color-dark-rgb, [10, 116, 26]);
  --sis-color-ropewaydriveready-contrast: var(--ion-color-dark-contrast, #ffffff);
  --sis-color-ropewaydriveready-contrast-rgb: var(--ion-color-dark-contrast-rgb, [255, 255, 255]);
  --sis-color-ropewaydriveready-shade: var(--ion-color-dark-shade, #353535);
  --sis-color-ropewaydriveready-tint: var(--ion-color-dark-tint, #424242);

  /** Ropeway Warning **/
  --sis-color-ropewaywarning: var(--ion-color-warning, #db8829);
  --sis-color-ropewaywarning-rgb: var(--ion-color-warning-rgb, [10, 116, 26]);
  --sis-color-ropewaywarning-contrast: var(--ion-color-warning-contrast, #ffffff);
  --sis-color-ropewaywarning-contrast-rgb: var(--ion-color-warning-contrast-rgb, [255, 255, 255]);
  --sis-color-ropewaywarning-shade: var(--ion-color-warning-shade, #db6a29);
  --sis-color-ropewaywarning-tint: var(--ion-color-warning-tint, #f19c3b);

  /** Ropeway Alarm **/
  --sis-color-ropewayalarm: var(--ion-color-danger, #c20000);
  --sis-color-ropewayalarm-rgb: var(--ion-color-danger-rgb, [10, 116, 26]);
  --sis-color-ropewayalarm-contrast: var(--ion-color-danger-contrast, #ffffff);
  --sis-color-ropewayalarm-contrast-rgb: var(--ion-color-danger-contrast-rgb, [255, 255, 255]);
  --sis-color-ropewayalarm-shade: var(--ion-color-danger-shade, #7a0000);
  --sis-color-ropewayalarm-tint: var(--ion-color-danger-tint, #db0000);

  /** MeteoStation Offline **/
  --sis-color-meteostationoffline: var(--ion-color-medium, #8f8f8f);
  --sis-color-meteostationoffline-rgb: var(--ion-color-medium-rgb, [143, 143, 143]);
  --sis-color-meteostationoffline-contrast: var(--ion-color-medium-contrast, #000000);
  --sis-color-meteostationoffline-contrast-rgb: var(--ion-color-medium-contrast-rgb, [0, 0, 0]);
  --sis-color-meteostationoffline-shade: var(--ion-color-medium-shade, #7e7e7e);
  --sis-color-meteostationoffline-tint: var(--ion-color-medium-tint, #9a9a9a);

  /** MeteoStation Normal **/
  --sis-color-meteostationnormal: var(--ion-color-primary, #0a741a);
  --sis-color-meteostationnormal-rgb: var(--ion-color-primary-rgb, [10, 116, 26]);
  --sis-color-meteostationnormal-contrast: var(--ion-color-primary-contrast, #ffffff);
  --sis-color-meteostationnormal-contrast-rgb: var(--ion-color-primary-contrast-rgb, [255, 255, 255]);
  --sis-color-meteostationnormal-shade: var(--ion-color-primary-shade, #096617);
  --sis-color-meteostationnormal-tint: var(--ion-color-primary-tint, #238231);

  /** MeteoStation Warning **/
  --sis-color-meteostationwarning: var(--ion-color-warning, #db8829);
  --sis-color-meteostationwarning-rgb: var(--ion-color-warning-rgb, [10, 116, 26]);
  --sis-color-meteostationwarning-contrast: var(--ion-color-warning-contrast, #ffffff);
  --sis-color-meteostationwarning-contrast-rgb: var(--ion-color-warning-contrast-rgb, [255, 255, 255]);
  --sis-color-meteostationwarning-shade: var(--ion-color-warning-shade, #db6a29);
  --sis-color-meteostationwarning-tint: var(--ion-color-warning-tint, #f19c3b);

  /** MeteoStation Alarm **/
  --sis-color-meteostationalarm: var(--ion-color-danger, #c20000);
  --sis-color-meteostationalarm-rgb: var(--ion-color-danger-rgb, [10, 116, 26]);
  --sis-color-meteostationalarm-contrast: var(--ion-color-danger-contrast, #ffffff);
  --sis-color-meteostationalarm-contrast-rgb: var(--ion-color-danger-contrast-rgb, [255, 255, 255]);
  --sis-color-meteostationalarm-shade: var(--ion-color-danger-shade, #7a0000);
  --sis-color-meteostationalarm-tint: var(--ion-color-danger-tint, #db0000);

  /** bottom drawer grid **/
  --sis-grid-border-color: var(--ion-color-primary);
  --sis-grid-border-width: 2px;
  --sis-grid-border-style: solid;

  /** --ion-font-family: MyriadPro; **/
  --ion-font-family: Myriad Pro, sans-serif;
  --ion-font-style: normal;
  --ion-font-weight: 400;

  /** SisMap Editor **/
  --sis-color-selected-item: var(--ion-color-primary);
  --sis-color-selected-item-map: var(--ion-color-secondary-tint);
}

@font-face {
  font-family: 'Myriad Pro';
  font-style: normal;
  font-weight: normal;
  src: local('Myriad Pro'), url('../assets/fonts/MYRIADPRO-REGULAR.woff') format('woff');
}

@font-face {
  font-family: 'ASD Piktogramme Medium-Negativ';
  src: url('../assets/fonts/ASDPiktogramme-Medium-Negativ.woff2') format('woff2'),
    url('../assets/fonts/ASDPiktogramme-Medium-Negativ.woff') format('woff');
}

@font-face {
  font-family: 'ASD Piktogramme Medium-Positiv';
  src: url('../assets/fonts/ASDPiktogramme-Medium-Positiv.woff2') format('woff2'),
    url('../assets/fonts/ASDPiktogramme-Medium-Positiv.woff') format('woff');
}

@font-face {
  font-family: 'Neue Haas Grotesk Disp W04 65Md';
  src: url('../assets/fonts/5548988/b0f06a93-70cb-46c4-a214-5050c3443812.woff2') format('woff2'),
    url('../assets/fonts/5548988/c4f1d9a2-1f03-44cd-8f25-64e3698567d1.woff') format('woff');
}
