@import '~@ionic/angular/css/core.css';
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';

@import '~leaflet/dist/leaflet.css';
@import '~leaflet.fullscreen/Control.FullScreen.css';

html,
body {
  touch-action: none;
  overflow: hidden;
}

body {
  margin: 0;
  display: contents;
}

.sis-map-tooltip {
  pointer-events: none;

  svg {
    width: 20px;
    height: 20px;
    display: inline-block;
    vertical-align: middle;
  }

  span {
    font-weight: normal;
    font-size: 10pt;
    line-height: 20px;
    display: inline-block;
    vertical-align: middle;
  }

  .leaflet-popup-content-wrapper {
    border-radius: 50px;
    text-align: center;
  }

  .leaflet-popup-content {
    margin: 1px;
  }
}

.sis-map-tooltip-andermatt {
  span {
    font-family: 'Neue Haas Grotesk Disp W04 65Md';
    margin: 2px 8px 0px 8px;
  }

  svg {
    margin: 0px 0.5px 0px -4px;
  }
}

.sis-map-tooltip-sisag {
  span {
    font-weight: bold;
    font-family: Helvetica, Arial, sans-serif;
    margin: 0px 8px 0px 8px;
  }

  svg {
    border-radius: 10px;
    margin: 0px 0px 0px -4px;
  }
}

.sis-map-place-andermatt {
  span {
    font-family: 'Neue Haas Grotesk Disp W04 65Md';
  }
}

.sis-sismap-webcam-16-9-modal,
.sis-sismap-webcam-modal {
  @media screen and (max-width: 991px) {
    --height: 100%;
    --width: 100%;
  }

  --width: 1280px;
  --height: 764px; //720px + 44px;
}

.sis-sismap-webcam-4-3-modal {
  @media screen and (max-width: 991px) {
    --height: 100%;
    --width: 100%;
  }

  --width: 1024px;
  --height: 812px; //768px + 44px;
}

.sis-sismap-webcam-3-2-modal {
  @media screen and (max-width: 991px) {
    --height: 100%;
    --width: 100%;
  }

  --width: 1152px;
  --height: 812px; //768px + 44px;
}

.vertical-align-content > * {
  display: flex !important;
  align-content: center !important;
  align-items: center !important;
}

::-webkit-scrollbar {
  display: none;
}

ion-toast {
  touch-action: none;
}

* {
  @media (any-hover: hover) {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    user-select: none;
  }
}

a {
  color: var(--ion-color-secondary);
  text-decoration: none;
}

.sis-global-toast {
  color: black;
  font-size: large;
  --border-radius: 6px;
}

.sis-global-tooltip {
  white-space: pre-line;
}

.sis-global-text-overflow-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.sis-sismap-bottom-sheet-div {
  width: 100%;
  height: 100%;
  /*--border-radius: 10px 10px 0 0;*/
}
